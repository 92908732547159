import React from 'react';
import Styles from "./cesium1.module.css"
import Cityset1 from "../citysetting/citytitle1"
import Hanrei from "../../containers/hanrei"
import CesiumContainer from "../../pages/cesiumContainer"
import {Link} from "gatsby"

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);
 
  return [value, setValue];
};

const App = (props) => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
  
  const onChange = event => setValue(event.target.value);
  const city1 = {value};
  const city1nfd = city1.value.normalize( 'NFD' ) ;
  
  const kmlsrc1 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+props.no+'/'+ city1nfd + '_' + props.kml_file;
  const kmldown1 = 'https://empire-dev3.kashika.net/kml/'+props.no+'/'+ city1nfd + '_' + props.kml_file;
  // const kmlsrc1 = 'https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+props.no+'/'+ city1.value + '_' + props.kml_file;
  // const kmldown1 = 'https://empire-dev3.kashika.net/kml/'+props.no+'/'+ city1.value + '_' + props.kml_file;


  return (
    
    <div style={{display: "flex"}}>
      <div className={Styles.panel}>
        <div>
          <Cityset1 />
        </div>
        <div className={Styles.previewheader}>プレビュー</div>
        <div className={Styles.preview}>
        {/* <a href={'https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid='+props.no+'/'+ value + '_' + props.kml_file} target="iframe1">選択した都市へ移動</a> */}
          <iframe
            name="iframe1"
            src={kmlsrc1}
            frameBorder="0"
            allowFullScreen
            title="cesium"
            width="100%"
            height="500px"
          />
            {/* <CesiumContainer
              dir="/kml"
              file="/sample.kml"
              kmlurl="/kml/sample.kml"
            /> */}

        </div>
        <div className={Styles.buttondiv}>
          <a className={Styles.button} href={kmldown1} download>KMLダウンロード</a>
        </div>
      </div>
    </div>
  );
};
 
export default App;



{/*

<iframe src="https://empire-dev3.kashika.net/Cesium/Apps/kashika/?sourceid=H26小売業販売額×H27人口総数/福岡県糸島市_H26小売業販売額×H27人口総数.kmz"
  frameBorder="0"
  allowFullScreen
  title="cesium"
  width=100%
  height=400px
>
*/}